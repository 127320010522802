@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    border: none;
    outline: none;

    transition: all 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  body {
    --color-primary: #f15b43;
    --color-accent: #65c9bb;
    --color-secondary: #263b5c;
    --color-white: #fff;
    --color-light: #777784;
    --color-dark: #13233c;

    -webkit-overflow-scrolling: touch;
  }

  body::-webkit-scrollbar {
    width: 4px;
  }

  body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
  }

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  body:has(.show) {
    overflow: hidden;
  }
}

@layer header {
  .header {
    padding-block-start: 48px;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 33;
  }

  .nav {
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .nav__burger {
    font-size: 48px;
    color: var(--color-white);

    cursor: pointer;

    display: none;
  }

  .mobile {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;

    padding: 2rem;

    background-color: var(--color-white);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .mobile > .close {
    position: absolute;
    left: 32px;
    top: 32px;

    font-size: 32px;
    background: transparent;

    cursor: pointer;
  }

  .mobile ul {
    list-style-type: none;
  }
  .mobile ul > li {
    font-size: 20px;
    margin-block-start: 2rem;
    text-align: center;
  }
  .mobile ul > li > a {
    color: var(--color-secondary);
  }
  .mobile ul > li > a:hover {
    color: var(--color-primary);
  }

  .mobile .lang {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  .mobile .action {
    margin-block-start: 2rem;
    border-radius: 50px;
    background-color: var(--color-primary);
    width: 210px;
    height: 60px;
    border: none;

    cursor: pointer;
  }
  .mobile .action > a {
    text-decoration: none;
    color: var(--color-white);

    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  .nav__brand {
    width: 220px;
  }

  .nav__menu > ul {
    list-style-type: none;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
  }
  .nav__menu > ul > li {
    padding-inline: calc(0.4vw);
  }
  .nav__menu > ul > li > a {
    color: var(--color-white);
    text-decoration: none;
    font-weight: 700;
    size: 1.25rem;
    line-height: 1.5rem;
  }
  .nav__menu > ul > li:hover > a {
    color: var(--color-secondary);
  }

  .nav__lang {
    display: flex;
    flex-direction: row;
    list-style-type: none;
  }
  .nav__lang > li.lang-item {
    color: var(--color-white);
    font-weight: 300;
    size: 18px;
    line-height: 22px;
    letter-spacing: 20%;

    cursor: pointer;
  }
  .nav__lang > li.lang-item.active {
    font-weight: 700;
  }
  .nav__lang > li.lang-item:not(:nth-child(1)):before {
    content: "/";
    margin-inline: 0.25rem;
  }
  .nav__lang > li.lang-item:hover {
    color: var(--color-primary);
  }

  .nav__call-action {
    border-radius: 50px;
    background-color: var(--color-accent);
    width: 210px;
    height: 60px;
    border: none;

    cursor: pointer;
  }
  .nav__call-action:hover {
    background-color: var(--color-primary);
  }
  .nav__call-action > a {
    text-decoration: none;
    color: var(--color-white);

    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (width < 600px) {
    .nav__call-action {
      display: none;
    }
  }

  @media screen and (width < 1150px) {
    .nav__lang,
    .nav__menu {
      display: none;
    }
    .nav__burger {
      display: block;
    }
  }
}

@layer main {
  .main {
    background: linear-gradient(
        0deg,
        rgba(136, 143, 154, 0.6),
        rgba(136, 143, 154, 0.6)
      ),
      url(background2.png);

    background-size: cover;

    display: flex;
    align-items: flex-end;
  }

  .main__top-right {
    background-image: url(righttop.png);
    width: 600px;
    height: 600px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .main__info-block {
    width: 100%;
    max-width: 550px;
    background: rgba(217, 217, 217, 0.7);
    border-radius: 0px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 40px;
    isolation: isolate;
  }
  .main__info-block > span {
    color: var(--color-light);
    font-weight: 900;
    font-size: calc(1rem + 4.5vw);
    line-height: calc(1rem + 5vw);
  }
  .main__info-block > span:first-child {
    font-weight: 100;
  }
  .main__info-block > .info-block__item {
    width: max-content;
    background: #f15b43;

    border-radius: 50px;
    margin-block-start: 50px;
    padding: 20px 40px;

    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: var(--color-white);

    cursor: pointer;
  }
  .main__info-block > .info-block__item:hover {
    background-color: var(--color-secondary);
  }
}

@layer soc-net {
  .soc-net {
    position: fixed;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    list-style-type: none;

    z-index: 1;
  }

  @media screen and (width < 600px) {
    .soc-net {
      display: none;
    }
  }

  .soc-net__item > a {
    margin-block: 0.5rem;
    font-size: clamp(24px, 2.2vw, 3rem);
    color: var(--color-dark);
  }

  .soc-net__item:has(:hover) > a {
    color: var(--color-hover);
  }
}

@layer about-us {
  .about-us {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    justify-items: stretch;
    align-items: center;
    justify-content: space-between;
    align-content: center;
  }
  .about-us > *:nth-child(1) {
    height: 100%;
  }
  @media screen and (width > 600px) {
    .about-us {
      grid-template-columns: repeat(2, 1fr);
    }
    .about-us > *:nth-child(1),
    .about-us > *:nth-child(2) {
      grid-column: 1/3;
    }
  }
  @media screen and (width > 1000px) {
    .about-us {
      grid-template-columns: repeat(4, 1fr);
    }
    .about-us > *:nth-child(1) {
      grid-column: 1/3;
    }
    .about-us > *:nth-child(2) {
      grid-column: 3/5;
    }
  }
  .about-us img {
    height: 100%;
    object-fit: cover;
  }

  .about-us__link {
    position: relative;
  }
  .about-us__link::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(35, 59, 110, 0.95) 18.4%,
      rgba(0, 0, 0, 0.2) 52.56%
    );
  }
  .about-us__link:hover::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(247, 148, 19, 0.95) 18.4%,
      rgba(88, 54, 9, 0.2) 52.56%
    );
  }
  .about-us__link > .text {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: var(--color-white);

    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .about-us__link.clip {
    clip-path: circle(50%);
  }

  .about-us__card > .subtitle {
    margin-inline-start: 2rem;
    margin-block-start: 2rem;

    color: var(--color-primary);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }
  .about-us__card > .title {
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;

    margin-block-start: 1rem;
  }
  .about-us__card > .text {
    color: var(--color-light);
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: justify;

    margin-block-start: 1.5rem;
  }
  .about-us__card > .text > a {
    font-weight: 600;
    text-decoration: revert;
  }

  .about-us__list {
    list-style-type: none;
    align-items: center;
  }
  .about-us__list > li {
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
  }

  .about-us__banner {
    position: relative;
  }
  .about-us__banner > span {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    text-align: end;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;

    color: var(--color-white);
  }
}

@layer join-us {
  .join-us {
    --bg-color: #f9f9f9;
    display: grid;
    grid-template-columns: 1fr;
    background-color: var(--bg-color);
  }
  @media screen and (width > 600px) {
    .join-us {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .join-us > * {
    background-color: var(--color-white);
    min-height: 280px;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
  .join-us > *:first-child {
    border-radius: 10px 0px 0px 0px;
  }
  .join-us > *:last-child {
    border-radius: 0px 0px 10px 0px;
  }

  .join-us__image > img {
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
  }

  .join-us__item {
    padding: calc(1rem + 1vw);
    display: flex;
    flex-direction: column;
  }
  .join-us__item > .subtitle {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    margin-inline-start: 2rem;
  }
  .join-us__item > .title {
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;

    margin-block-start: 1rem;
  }
  .join-us__item > .text {
    color: var(--color-light);

    font-weight: 300;
    font-size: 20px;
    line-height: 24px;

    margin-block-start: 1.5rem;
  }
  .join-us__item > .action {
    border-radius: 50px;
    background-color: var(--color-primary);
    padding: 20px calc(1rem + 3vw);

    margin-block-start: 1.5rem;

    text-decoration: none;
    color: var(--color-white);
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    cursor: pointer;
  }
  .join-us__item > .action:hover {
    background-color: var(--color-secondary);
  }
  .join-us__item:has(:nth-child(even)) > .action {
    margin-inline-start: auto;
  }
}

@layer out-work {
  .out-work {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.9),
        rgba(255, 255, 255, 0.9)
      ),
      url(out-work.png);
  }

  .out-work__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    color: var(--color-dark);
  }

  .out-work__col {
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    justify-content: space-around;
    gap: 2rem;

    margin-block-start: calc(1rem + 3vw);
  }
  .out-work__col > .out-work__card:hover {
    scale: 1.25;
    box-shadow: 0px 10px 100px 10px rgba(0, 0, 0, 0.55);
  }
  .out-work__col > .out-work__card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    align-content: center;

    background: var(--color-white);
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    cursor: pointer;

    text-decoration: none;
  }
  .out-work__col > .out-work__card > .image {
    object-fit: cover;
    aspect-ratio: 16/9;
    border-radius: 10px 10px 0px 0px;
  }
  .out-work__col > .out-work__card > .title {
    margin-inline: 40px;
    margin-block-start: 2rem;

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: var(--color-dark);
  }
  .out-work__col > .out-work__card > .text {
    margin-inline: 40px;
    margin-block-start: 2.5rem;
    margin-block-end: 2rem;

    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    color: var(--color-dark);
  }
}

@layer charity {
  .charity {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .charity > * {
    flex-grow: 0;
    flex-shrink: 1;
  }

  .charity__title {
    flex-basis: 100%;

    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    color: var(--color-dark);
  }

  .charity__col {
    flex-basis: 35%;
    margin-block-start: 1rem;

    color: var(--color-dark);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    min-width: 280px;
  }

  .charity__subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .charity__text {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
  }
  .charity__text > strong {
    color: var(--color-primary);
  }
  .charity__col.card {
    padding: 2.5rem;

    background: var(--color-white);
    box-shadow: 0px 12px 100px rgba(0, 0, 0, 0.25);
  }
  .charity__col.card > .title {
    margin-block-end: 1rem;
  }
  .charity__col.card > .text {
    margin-block-start: 1rem;
    width: 100%;
  }
  .charity__col.card > .image {
    margin-block-start: 1.5rem;
  }
}

@layer galery {
  .galery {
    display: flex;
    flex-wrap: wrap;
  }
  .galery > * {
    max-width: 25%;
  }
  .galery > .half {
    max-width: 50%;
  }
  .galery > .full {
    max-width: 100%;
  }
  .galery > .full:last-child {
    margin-block-end: -150px;
  }

  @media screen and (width < 50em) {
    .galery > * {
      flex: 1;
    }
    .galery > * {
      max-width: 100%;
    }
  }
}

@layer footer {
  .footer {
    background-image: url(bg.png);
    background-size: cover;
    isolation: isolate;
  }

  .contacts__about-us > .title {
    text-align: start;
  }

  .contacts {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-end;
    justify-content: space-between;
  }
  @media screen and (width < 1170px) {
    .contacts {
      flex-direction: column;
      align-items: center;
    }
    .contacts > * {
      margin-block-start: 1rem;
    }

    .contacts__about-us > .title {
      text-align: end;
    }
  }
  .contacts > * {
    width: auto;
  }
  .contacts > .contacts__about-us {
    max-width: 420px;
  }
  .contacts > .contacts__soc-net {
    list-style-type: none;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }

  .contacts__soc-net__item > a {
    margin-inline: 0.5rem;

    font-size: calc(1rem + 1vw);
    color: var(--color-white);
  }
  .contacts__soc-net__item:hover > a {
    color: var(--color-hover);
  }

  .contacts_row {
    --color-text: #6293c1;
  }
  .contacts_row > .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: var(--color-white);

    margin-block-end: 1rem;
  }
  .contacts_row .text {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    color: var(--color-text);
  }

  .contact {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    display: block;

    text-decoration: none;
  }
  .contact > i {
    font-size: calc(1rem + 1vw);
    margin-inline-end: 1.5rem;
    margin-block-end: 0.75rem;

    color: var(--color-white);
  }
  .contact > .text > strong {
    color: var(--color-white);

    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .footer__copyright {
    padding-block: 2rem;
    background-color: #13233c;
    display: flex;
    justify-content: center;
  }
  .copyright {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #6c829c;

    padding-inline: 1rem;
  }
  .copyright a {
    text-decoration: none;
    color: var(--color-white);
    font-weight: 900;
  }
}

@layer utils {
  .wrapper {
    width: 85vw;
    margin-inline: auto;
    padding-block: clamp(1rem, 5vw, 10rem);
    padding-inline: 1rem;
    box-sizing: content-box;
  }
  .block {
    height: 100vh;
  }
  .full-bleed {
    box-shadow: 0 0 0 100vmax var(--bg-color);
    clip-path: inset(0 -100vmax);
  }
  .padding-top-0 {
    padding-top: 0;
  }
  .padding-bottom-32 {
    padding-block-end: calc(1rem + 1vw);
  }
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 100px 100vw #000000f0;
    z-index: 200;
    isolation: isolate;
  }
  .show {
    display: flex;
  }
  .hide {
    display: none;
  }
  .aspect-ratio-1-1 {
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}
